import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableBanner from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content, ReorderButton } from './styles';

import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import Select from '../../components/Select';
import { EditorialStatusEnum } from '../../utils/EditorialStatusEnum';

interface Data {
  id: string;
  code: number;
  editorial: string;
  url: string;
  banner_url: string;
  priority: number;
  active: boolean;
  is_schedule: boolean;
  enableMap: boolean
  count: number;
  isUpButtonEnable: boolean;
  isDownButtonEnable: boolean;
  title: string;
  deletedAt: Date | undefined;
}

interface IBanner {
  id: number;
  editorial: string;
  url: string;
  banner_url: string;
  priority: number;
  active: boolean;
  is_schedule: boolean;
  enableMap: boolean
  count: number;
  isUpButtonEnable: boolean;
  isDownButtonEnable: boolean;
  title: string;
  deletedAt: Date | undefined;
}

function createData(
  id: string,
  code: number,
  editorial: string,
  url: string,
  banner_url: string,
  priority: number,
  active: boolean,
  is_schedule: boolean,
  enableMap: boolean,
  count: number,
  isUpButtonEnable: boolean,
  isDownButtonEnable: boolean,
  title: string,
  deletedAt: Date | undefined

): Data {
  return { id, code, editorial, url, banner_url, priority, active, is_schedule, enableMap, count, isUpButtonEnable, isDownButtonEnable, title, deletedAt };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'count', numeric: true, disablePadding: true, label: 'Clicks' },
  { id: 'editorial', numeric: false, disablePadding: false, label: 'Editoria' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Titulo' },
  { id: 'url', numeric: false, disablePadding: false, label: 'Link' },
  { id: 'active', numeric: false, disablePadding: false, label: 'Ativo' },
  { id: 'is_schedule', numeric: false, disablePadding: false, label: 'Agendado' },
  { id: 'banner_url', numeric: false, disablePadding: false, label: 'Imagem' },
  { id: 'enableMap', numeric: false, disablePadding: false, label: 'Mostra em mapa' },
  { id: 'priority', numeric: true, disablePadding: false, label: 'Reordenar' },
];

const Banner = () => {
  const [rows, setRows] = useState<IBanner[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editorials, setEditorials] = useState<{ label: string, value: string }[]>(
    [
      { value: EditorialStatusEnum.DEUS_CONOSCO, label: 'Deus conosco' },
      { value: EditorialStatusEnum.FAMILIA_DOS_DEVOTOS, label: 'Família dos devotos' },
      { value: EditorialStatusEnum.HOME, label: 'Home' },
      { value: EditorialStatusEnum.APARECIDA_PLAY, label: 'Aparecida Play' },
      { value: "all", label: 'Todos' },
    ]
  )

  const [selectedEditorial, setSelectedEditorial] = useState<string>('all')
  const [status, setStatus] = useState<string>("true")


  const { addToast } = useToast();

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    initScreen()
  }, [deleted, refresh]);

  const initScreen = () => {

    setLoading(true)
    getData()
  }

  const getData = useCallback(async () => {
    api.post('/banners/filter', { editorial: selectedEditorial == "all" ? "" : selectedEditorial, status: status })
      .then(response => {
        setRows(response.data);
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        addToast({
          type: 'error',
          title: 'Erro ao buscar os banners',
        });
      });
  }, [selectedEditorial, status, rows]);

  const onDelete = useCallback(async (id: number) => {
    api.delete(`/banners/${id}`)
      .then(response => {
        getData()
         addToast({
          type: 'success',
          title: 'Banner status alterado com sucesso!',
        });
      });
  }, [[selectedEditorial, status, rows]]);

  const onUpload = useCallback((row) => {
    history.push('/banner/upload', { id: row.code });
  }, [history]);

  const onCreate = useCallback(() => {
    history.push('/banner/new');
  }, [history]);

  const onEdit = useCallback((row) => {
    history.push('/banner/edit', { id: row.code });
  }, [history]);

  const onSchedule = useCallback((row) => {
    history.push('/banner/schedule', { id: row.code });
  }, [history]);

  const onCancelSchedule = useCallback((row) => {
    api.put(`/banners/${row.code}/schedule`, {
      schedule_publication_start: null,
      schedule_publication_end: null,
      active: true,
    }).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Publicação cancelada com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast]);

  const bannerRows = useMemo(() => {

    return rows.map(row => createData(row.id.toString(), row.id, row.editorial, row.url,
      row.banner_url, row.priority, row.active, row.is_schedule, row.enableMap, row.count, row.isUpButtonEnable, row.isDownButtonEnable, row.title, row.deletedAt));

  }, [rows]);


  const handleChangePosition = useCallback(async (id: string, isUp: boolean) => {

    api.patch(`/banners/${id}/position`, { isUp: isUp })
      .then(newRows => {
        getData()
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Não foi possível alterar a posição!',
          description: error.response.data.message,
        });
      })

  }, [selectedEditorial, status, rows]);

  const handleSelectedEditorial = useCallback((data: NamedNodeMap) => {
    console.log(data)
    const [, id] = data[2].nodeValue!.split('editorial');
    console.log(id)
    setSelectedEditorial(id)
  }, []);

  const handleSelectedStatus = useCallback((data: NamedNodeMap) => {
    const [, id] = data[2].nodeValue!.split('status');
    console.log(id)
    setStatus(JSON.parse(id))
  }, []);



  const handleSubmitSearch = () => {
    if ((!selectedEditorial && status == undefined) || status == undefined) {
      addToast({
        type: "error",
        title: 'Valores invalidos',
        description: 'status deve ser selecionado para realizar o filtro'
      });

      return;
    }

    setLoading(true)
    getData()

  }


  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.count}</TableCell>
      <TableCell align="left">{row.editorial}</TableCell>
      <TableCell align="left">{row.title}</TableCell>
      <TableCell align="left"><a href={row.url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.url.substring(0, 12)}...</a></TableCell>
      <TableCell align="left">
        <Switch
          checked={row.deletedAt == undefined}
          onChange={async () => {
            const id = row.id;
            onDelete(id);
          }}
        />

      </TableCell>
      <TableCell align="left">{row.is_schedule ? 'Sim' : 'Não'}</TableCell>
      <TableCell align="left"><a href={row.banner_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>Abrir imagem</a></TableCell>
      <TableCell align="left">
        <Switch
          checked={row.enableMap}
          onChange={async () => {
            const id = row.id;
            api.patch(`/banners/switch/map/${id}`)
              .then((result) => {
                const row = rows.find(item => item.id == id)
                if (row) {
                  row.enableMap = !row.enableMap;

                  setRows([...rows])
                }
              })
          }}
        />

      </TableCell>

      <TableCell align="left">
        <div style={{ display: 'flex' }}>
          <ReorderButton onClick={() => handleChangePosition(row.id, false)}><IoIosArrowUp /></ReorderButton>
          <ReorderButton onClick={() => handleChangePosition(row.id, true)}><IoIosArrowDown /></ReorderButton>
        </div>
      </TableCell>
    </>
  ), [handleChangePosition]);

  return (
    <>
      <StandardContainer>
        <Header />
        <Content>
          <Form
            ref={formRef}
            onSubmit={() => { }}
          >
            <div>
              <Select
                name="editorial"
                label="Selecione o Editorial"
                defaultOption={selectedEditorial}
                options={editorials}
                onChange={e => handleSelectedEditorial(e.target.attributes)}
              />
            </div>


            <div>
              <Select
                name="status"
                label="Selecione o status"
                defaultOption={status}
                options={[{ label: 'Ativo', value: 'true' }, { label: 'Inativo', value: 'false' }]}
                onChange={e => handleSelectedStatus(e.target.attributes)}
              />
            </div>
            <div>

              <Button type="button" loading={loading} onClick={handleSubmitSearch} style={{ maxWidth: 150 }}>Filtrar</Button>
            </div>
          </Form>
          <TableBanner<Data, HeadCell[]>
            title='Banner'
            headCells={headCells}
            rows={bannerRows}
            onCreate={onCreate}
            onUpload={onUpload}
            onEdit={onEdit}
            onSchedule={onSchedule}
            onCancelSchedule={onCancelSchedule}
            tableBodyCells={tableBodyCells}
            loading={loading}
          />
        </Content>
      </StandardContainer>
    </>

  )
}

export default Banner;
